form {
    input::placeholder,
    input::-webkit-input-placeholder,
    input::-moz-placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder {
        font-size: 1rem;
        color: #888;
    }
}



.clear-btn{
    margin-top: 20px;
}
.search-container {
    text-align: center;
    background: white;
    padding: 40px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    margin: auto;
}

.domain-serach {
    margin-bottom: 20px;
    font-size: 26px;
    color: #333;
}

// .domain-search-input-group {
//     display: flex;
// }

// .domain-search-input {
//     flex: 1;
//     padding: 15px;
//     border: 2px solid #007bff;
//     border-radius: 8px 0 0 8px;
//     outline: none;
//     font-size: 16px;
//     transition: border-color 0.3s;
// }

.search-input:focus {
    border-color: #0056b3;
}

// .domain-search-button {
//     padding: 15px 20px;
//     border: none;
//     background-color: #007bff;
//     color: white;
//     border-radius: 0 8px 8px 0;
//     cursor: pointer;
//     font-size: 16px;
//     transition: background-color 0.3s;
// }

.domain-search-button:hover {
    background-color: #0056b3;
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    text-align: left;
}
.bg-result-row{
    background: #B8B8B8;
}
.result-header{
    color: #9a9a9a;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
}

